import React from "react";
import Layout from "layout";
import { Row, Col } from "react-grid-system";
import Hero from "common/hero";
import Seo from "common/seo";
import CategoryDetails from "common/category-details";
import { TypeA, TypeC } from "common/callouts";

import heroImageRef from "images/sun/sunproducts/sunproducts-hero.webp";
import productInfoImg from "images/sun/sunproducts/sunproduct-datavisuals.webp";

import img1 from "images/sun/sunproducts/agedefense-product.webp";
import img2 from "images/sun/sunproducts/oilcontrol-product.webp";
import img3 from "images/sun/sunproducts/advanced-hydration-product.webp";
import img4 from "images/sun/sunproducts/mineral-product.webp";

import img5 from "images/callouts/resources2x.webp";
import img6 from "images/callouts/cout-footer-daily-skin.webp";
import img7 from "images/callouts/cout-footer-journal.webp";

import img8 from "images/sun/sunproducts/tinted-mineral-product.webp";

import "./eucerinsunproducts.scss";

const MyHero = () => <Hero className="inner-hero" bgImagePath={heroImageRef} />;

function IndexPage({ pageContext }) {
  return (
    <Layout
      pageClass="page-sun-eucerinsunproducts"
      breadcrumbData={pageContext.breadcrumb}
      hero={<MyHero />}
    >
      <Seo
        pageTitle="Eucerin Sun Products"
        productDescription="Learn about Eucerin Sun products for all skin types and tones"
      />
      <div className="inner-body-content">
        <section className="inner-centered-container">
          <CategoryDetails
            headBgColor="#E07810"
            headText="Eucerin sun Products"
            infoText="Eucerin<sup>&reg;</sup> Sun, featuring 5 AOX Shield<sup>&trade;</sup>"
            bodyText="Our Inclusive Therapeutic Approach goes beyond broad-spectrum sun protection to offer a cosmetically elegant solution for all skin tones and clinically proven benefits for specific skin needs."
            productInfoImg={productInfoImg}
            productInfoImgAlt="Eucerin Sun Products"
            imgHeader="&nbsp;"
            imgCaption="<p></p><p>&nbsp;</p>"
          >
            <h5 className="mango-tango">Formulated for specific skin types</h5>
            <p className="font-semi-bold">
              Go beyond broad-spectrum protection to provide clinically proven
              benefits for patients with:
            </p>
            <ul className="mango-tango-bullets">
              <li>
                <span>Signs of skin aging</span>
              </li>
              <li>
                <span>Acne-prone skin</span>
              </li>
              <li>
                <span>Dry skin</span>
              </li>
              <li>
                <span>Sensitive skin</span>
              </li>
            </ul>
          </CategoryDetails>
        </section>
        <section className="callouts-container">
          <div className="inner-centered-container">
            <Row>
              <Col sm={12} md={8} lg={4} order={{ xs: 2, lg: 1 }}>
                <TypeC
                  head1={`Eucerin<sup>&reg;</sup> Sun Tinted Sensitive Mineral Face`}
                  head1Css="mango-tango"
                  buttonText="See product information"
                  buttonUrl="/sun/eucerinsunproducts/tintedmineral"
                  className="center-v"
                >
                  <p>
                    24% zinc oxide sunscreen with a sheer, iron oxide, universal
                    tint that’s blendable with all skin tones
                  </p>
                </TypeC>
              </Col>
              <Col sm={12} md={4} lg={2} order={{ xs: 1, lg: 2 }}>
                <img
                  src={img8}
                  alt="Eucerin® Sun Sun Tinted Sensitive Mineral Face"
                  className="cout-product-img"
                />
              </Col>

              <Col sm={12} md={8} lg={4} order={{ xs: 4, lg: 3 }}>
                <TypeC
                  head1={`Eucerin<sup>&reg;</sup> Sun Age Defense`}
                  head1Css="mango-tango"
                  buttonText="See product information"
                  buttonUrl="/sun/eucerinsunproducts/agedefense"
                  className="center-v"
                >
                  <p>
                    Formulated with hyaluronic acid to visibly reduce the look
                    of fine lines and wrinkles with continuous use after 1 week
                    <sup>1</sup>
                  </p>
                </TypeC>
              </Col>
              <Col sm={12} md={4} lg={2} order={{ xs: 3, lg: 4 }}>
                <img
                  src={img1}
                  alt="Eucerin® Sun Age Defense"
                  className="cout-product-img"
                />
              </Col>
            </Row>

            <Row>
              <Col sm={12} md={8} lg={4} order={{ xs: 2, lg: 1 }}>
                <TypeC
                  head1={`Eucerin<sup>®</sup> Sun Clear Skin`}
                  head1Css="mango-tango"
                  buttonText="See product information"
                  buttonUrl="/sun/eucerinsunproducts/oilcontrol"
                  className="center-v"
                >
                  <p>
                    Non-comedogenic and hypoallergenic formula with
                    oil-absorbing minerals and suitable for use on oily and
                    acne-prone skin
                  </p>
                </TypeC>
              </Col>
              <Col sm={12} md={4} lg={2} order={{ xs: 1, lg: 2 }}>
                <img
                  src={img2}
                  alt="Eucerin® Sun Clear Skin"
                  className="cout-product-img"
                />
              </Col>

              <Col sm={12} md={8} lg={4} order={{ xs: 4, lg: 3 }}>
                <TypeC
                  head1={`Eucerin<sup>&reg;</sup> Sun Advanced Hydration`}
                  head1Css="mango-tango"
                  buttonText="See product information"
                  buttonUrl="/sun/eucerinsunproducts/advancedhydration"
                  className="center-v"
                >
                  <p>
                    Water-resistant (80 minutes) sun protection that replenishes
                    skin’s natural moisture, leaving it hydrated and supple for
                    up to 8 hours<sup>1</sup>*
                  </p>
                </TypeC>
              </Col>
              <Col sm={12} md={4} lg={2} order={{ xs: 3, lg: 4 }}>
                <img
                  src={img3}
                  alt="Eucerin® Sun Advanced Hydration"
                  className="cout-product-img"
                />
              </Col>
            </Row>
            <Row>
              <Col sm={12} md={8} lg={4} order={{ xs: 4, lg: 3 }}>
                <TypeC
                  head1={`Eucerin<sup>&reg;</sup> Sun Sensitive Mineral`}
                  head1Css="mango-tango"
                  buttonText="See product information"
                  buttonUrl="/sun/eucerinsunproducts/sensitivemineral"
                  className="center-v"
                >
                  <p>
                    Water-resistant (80 minutes) hypoallergenic sun protection with 100%
                    naturally sourced zinc oxide<sup>†</sup>
                  </p>
                </TypeC>
              </Col>
              <Col sm={12} md={4} lg={2} order={{ xs: 3, lg: 4 }}>
                <img
                  src={img4}
                  alt="Eucerin® Sun Sensitive Mineral"
                  className="cout-product-img"
                />
              </Col>
            </Row>
          </div>
        </section>

        <div className="inner-centered-container">
          <p className="footnotes">
            *For sun protection, use as directed (see Drug Facts Box).<br/>
            <sup>&dagger;</sup>Based on zinc oxide active.
          </p>
          <p className="references">
            <span className="font-bold">Reference: 1.</span> Data on file.
            Beiersdorf Inc.
          </p>
        </div>

        <section className="callouts-container gradient-bg blue-grad">
          <div className="inner-centered-container">
            <Row>
              <Col sm={12} md={4}>
                <TypeA
                  imgRef={img5}
                  imgAlt="Resources for Sun Care"
                  caption="Find resources to support your sun care recommendations"
                  buttonText="BROWSE RESOURCES"
                  buttonUrl="/resources"
                  imgStyles={{ height: 165 }}
                />
              </Col>
              <Col sm={12} md={4}>
                <TypeA
                  imgRef={img6}
                  imgAlt="EXPLORE BODY CARE"
                  caption="Clinically proven therapeutic solutions for daily skin care"
                  buttonText="EXPLORE BODY CARE"
                  buttonUrl="/body"
                  imgStyles={{ height: 158 }}
                />
              </Col>
              <Col sm={12} md={4}>
                <TypeA
                  imgRef={img7}
                  imgAlt="VIEW JAAD ARTICLES"
                  caption="Discover how perspectives in sun protection are evolving"
                  buttonText="VIEW JOURNAL ARTICLES"
                  buttonUrl="https://www.jaad.org/issue/S0190-9622(22)X0002-9"
                  imgStyles={{ height: 125 }}
                  isExternalLink={true}
                  targetOpen="_blank"
                />
              </Col>
            </Row>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default IndexPage;
